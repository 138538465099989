import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faSoundcloud,
  faSquareFacebook,
  faTwitter,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import { useSite } from '@/contexts/siteContext';
import {
  createGoogleMapsSearchUrl,
  getSiteVenue,
  getThemeValue,
  getVenueStreet,
} from '@/lib/utils';
import { THEME_NAMES } from '@/constants';

interface ListItemProps {
  children: React.ReactNode;
  href: string;
}

const ListItem = ({ children, href }: ListItemProps) => {
  const { theme_name } = useSite();
  const buttonClasses = getThemeValue(theme_name, {
    [THEME_NAMES.DC]: 'btn',
    [THEME_NAMES.FLORIDA]: 'btn btn-outline-light',
    default: 'btn btn-outline-light',
  });

  return (
    <li>
      <a href={href} target="_blank" rel="noopener" className={buttonClasses}>
        {children}
      </a>
    </li>
  );
};

const AboutButtons = () => {
  const site = useSite();
  const venue = getSiteVenue(site);
  const {
    instagram,
    facebook,
    name,
    soundcloud,
    theme_name,
    tiktok,
    twitter,
    youtube,
  } = site;
  const { address } = venue;
  const { postal } = address || {};
  const street = getVenueStreet(address);

  const googleMapsLink =
    street && postal
      ? createGoogleMapsSearchUrl(`${street} ${postal}`)
      : undefined;

  const buttonPrefix = getThemeValue(theme_name, {
    [THEME_NAMES.DC]: '',
    [THEME_NAMES.FLORIDA]: `${name} on `,
    default: `${name} on `,
  });

  const listClasses = getThemeValue(theme_name, {
    [THEME_NAMES.DC]: 'list-unstyled',
    [THEME_NAMES.FLORIDA]: 'list-unstyled d-flex flex-wrap gap-2',
    default: 'list-unstyled',
  });

  return (
    <ul className={listClasses}>
      {googleMapsLink && (
        <ListItem href={googleMapsLink}>Get Directions &gt;&gt;</ListItem>
      )}
      {facebook && (
        <ListItem href={`https://facebook.com/${facebook}`}>
          {buttonPrefix}
          <FontAwesomeIcon icon={faSquareFacebook} /> Facebook
        </ListItem>
      )}
      {instagram && (
        <ListItem href={`https://instagram.com/${instagram}`}>
          {buttonPrefix}
          <FontAwesomeIcon icon={faInstagram} /> Instagram
        </ListItem>
      )}
      {twitter && (
        <ListItem href={`https://twitter.com/${twitter}`}>
          {buttonPrefix}
          <FontAwesomeIcon icon={faTwitter} /> Twitter
        </ListItem>
      )}
      {soundcloud && (
        <ListItem href={`https://soundcloud.com/${soundcloud}`}>
          {buttonPrefix}
          <FontAwesomeIcon icon={faSoundcloud} /> Soundcloud
        </ListItem>
      )}
      {tiktok && (
        <ListItem href={`https://tiktok.com/${tiktok}`}>
          {buttonPrefix}
          <FontAwesomeIcon icon={faTiktok} /> TikTok
        </ListItem>
      )}
      {youtube && (
        <ListItem href={`https://youtube.com/${youtube}`}>
          {buttonPrefix}
          <FontAwesomeIcon icon={faYoutube} /> YouTube
        </ListItem>
      )}
    </ul>
  );
};

export default AboutButtons;
