import { useSite } from '@/contexts/siteContext';
import axios from 'axios';
import { Fragment, useRef, useState } from 'react';

interface InputsProps {
  isError: boolean;
  isSubmitting: boolean;
}

const Inputs = ({ isError, isSubmitting }: InputsProps) => {
  const site = useSite();

  return (
    <Fragment>
      <label htmlFor="phone-signup" className="visually-hidden">
        Mobile phone number
      </label>
      <div className="input-group input-group-sm justify-content-center">
        <input type="hidden" name="brand" value={site.url_name} />
        <input
          type="tel"
          name="phone"
          id="phone-signup"
          inputMode="numeric"
          pattern="[0-9]{10}"
          title="10 digit phone number, no spaces or dashes"
          placeholder="Type mobile #"
          className="form-control phone-signup-input"
          required
        />
        <button type="submit" className="btn btn-dark" disabled={isSubmitting}>
          Subscribe
        </button>
      </div>
      {isError && (
        <div className="text-danger" role="alert">
          We encountered an error. Please try again later.
        </div>
      )}
    </Fragment>
  );
};

const PhoneSignup = () => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmitting(true);

    const body = Object.fromEntries(new FormData(event.currentTarget));

    try {
      await axios.post('/api/contact', body);
      setIsSuccess(true);
      formRef.current?.reset();
    } catch (err) {
      setIsError(true);
    }

    setIsSubmitting(false);
  }

  return (
    <form className="phone-signup" onSubmit={handleSubmit} ref={formRef}>
      <span>Sign up for SMS updates</span>
      <br />
      {isSuccess ? (
        <div>Success! Thank you</div>
      ) : (
        <Inputs isSubmitting={isSubmitting} isError={isError} />
      )}
    </form>
  );
};

export default PhoneSignup;
