import { useSite } from '@/contexts/siteContext';
import { getEventsJsonLd } from '@/lib/json-ld';
import { isNull } from '@/lib/utils';
import type { Event } from '@/types';

interface EventsRichSnippetProps {
  events: Array<Event>;
}

const EventsRichSnippet = ({ events }: EventsRichSnippetProps) => {
  const site = useSite();

  const snippet = getEventsJsonLd({ events, site });

  if (isNull(snippet)) {
    return null;
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(snippet) }}
    />
  );
};

export default EventsRichSnippet;
