import Head from 'next/head';
import type { GetStaticPaths, GetStaticProps } from 'next';

import Layout from '@/components/layout';
import EventsRichSnippet from '@/components/richSnippets/events';
import SchedulePage from '@/components/schedulePage/schedulePage';
import SiteContextProvider from '@/contexts/siteContext';
import { getEventsBySite, getSingleSiteData } from '@/lib/fetchers/sites';
import { DEFAULT_REVALIDATE_TIME, SITE_TYPES } from '@/constants';
import type { Event, SitePageProps, SiteParams } from '@/types';
import MembershipIndexPage from '@/components/membershipIndexPage';

interface SiteIndexProps extends SitePageProps {
  events: Array<Event>;
}

export const getStaticPaths: GetStaticPaths<SiteParams> = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps<
  SiteIndexProps,
  SiteParams
> = async ({ params }) => {
  const [site, events] = await Promise.all([
    getSingleSiteData(params?.site),
    getEventsBySite(params?.site),
  ]);

  return {
    props: { events, site },
    revalidate: Number.parseInt(
      process.env.ISR_REVALIDATE_TIME || DEFAULT_REVALIDATE_TIME,
    ),
  };
};

export default function SiteIndex({ events, site }: SiteIndexProps) {
  return (
    <SiteContextProvider site={site}>
      <Head>
        <title key="title">{site.title || site.name}</title>
        {site.meta_description && (
          <meta
            name="description"
            content={site.meta_description}
            key="meta:description"
          />
        )}
      </Head>
      {site.type === SITE_TYPES.MEMBERSHIP ? (
        <Layout withHeader={false} withFooter={false}>
          <MembershipIndexPage />
        </Layout>
      ) : (
        <Layout>
          <SchedulePage events={events} />
          <EventsRichSnippet events={events} />
        </Layout>
      )}
    </SiteContextProvider>
  );
}
