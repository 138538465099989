import React from 'react';

import type { Event } from '@/types';
import EventListing from '@/components/eventListing/eventListing';
import SchedulePageHeader from '@/components/schedulePage/schedulePageHeader';
import AboutButtons from '@/components/aboutButtons/aboutButtons';

interface SchedulePageProps {
  events: Array<Event>;
}

const SchedulePage = ({ events }: SchedulePageProps) => {
  return (
    <React.Fragment>
      <SchedulePageHeader />
      {events.length === 0 ? (
        <div className="d-flex justify-content-center my-4">
          <AboutButtons />
        </div>
      ) : (
        <>
          <h1 className="border-bottom border-gray mb-5 pb-3">Schedule</h1>
          {events.map((event) => (
            <EventListing event={event} key={event.name} />
          ))}
        </>
      )}
    </React.Fragment>
  );
};

export default SchedulePage;
