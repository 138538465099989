import { THEME_NAMES } from '@/constants';
import { useSite } from '@/contexts/siteContext';
import { isNull } from '@/lib/utils';

import PhoneSignup from '@/components/phoneSignup/phoneSignup';

interface PageHeaderProps {
  logoUrl: string;
  venueName: string | null;
}

const DcSchedulePageHeader = () => null;

const FloriaSchedulePageHeader = ({ logoUrl, venueName }: PageHeaderProps) => {
  const site = useSite();
  const shouldShowAboutLink =
    site.name &&
    site.navigation &&
    site.navigation.some(({ href }) => href === '/about');

  return (
    <div className="mb-5 text-center">
      {shouldShowAboutLink && (
        <a href="/about" className="d-inline-block mb-3">
          About {site.name}
        </a>
      )}
      <PhoneSignup />
    </div>
  );
};

const ThemeHeaders = {
  [THEME_NAMES.DC]: DcSchedulePageHeader,
  [THEME_NAMES.FLORIDA]: FloriaSchedulePageHeader,
  default: FloriaSchedulePageHeader,
};

function getHeaderComponent(themeName: string | null) {
  if (isNull(themeName)) {
    return ThemeHeaders.default;
  }

  return ThemeHeaders[themeName] ?? ThemeHeaders.default;
}

const SchedulePageHeader = () => {
  const { name: venueName, theme_name, url_logo } = useSite();

  if (!url_logo) {
    return null;
  }

  const HeaderComponent = getHeaderComponent(theme_name);

  return <HeaderComponent logoUrl={url_logo} venueName={venueName} />;
};

export default SchedulePageHeader;
