import React from 'react';

import { useSite } from '@/contexts/siteContext';
import {
  createGoogleMapsSearchUrl,
  getNavItems,
  getSiteVenue,
  getVenueStreet,
} from '@/lib/utils';
import { VenueAddress } from '@/types';

import 'animate.css';
import SocialIconLinks from '@/components/socialIconLinks';

const MembershipIndexPage = () => {
  const site = useSite();
  const navItems = getNavItems(site.navigation);
  const venue = getSiteVenue(site);

  return (
    <>
      <ul className="list-unstyled d-flex flex-column align-items-center animate__animated animate__fadeInUp animate__delay-1s">
        {navItems.map((item) => (
          <li key={item.href!} className="mb-1">
            <a
              className="nav-link fs-2 l-spacing-1 text-uppercase fw-bold p-2"
              href={item.href!}
            >
              {item.name!}
            </a>
          </li>
        ))}
      </ul>
      <Address venueAddress={venue.address} />
      <div className="animate__animated animate__fadeInUp animate__delay-3s">
        <SocialIconLinks size="lg" />
      </div>
    </>
  );
};

function Address({ venueAddress }: { venueAddress: VenueAddress | null }) {
  if (!venueAddress) {
    return null;
  }

  const { city, state, postal } = venueAddress;
  const street = getVenueStreet(venueAddress);

  return (
    <address className="text-center mt-5 mb-4 animate__animated animate__fadeInUp animate__delay-2s">
      <a
        href={createGoogleMapsSearchUrl(`${street} ${postal}`)}
        target="_blank"
        rel="noopener"
      >
        {street}, {city}, {state} {postal}
      </a>
    </address>
  );
}

export default MembershipIndexPage;
