import { format } from 'date-fns';

import EventActionButtons from '@/components/eventActionButtons/eventActionButtons';
import type { Event } from '@/types';
import { useSite } from '@/contexts/siteContext';
import { THEME_NAMES } from '@/constants';
import { getThemeValue } from '@/lib/utils';
import TechnologyLinks from '@/components/technologyLinks/technologyLinks';

interface EventListingProps {
  event: Event;
}

const themeDateFormats = {
  [THEME_NAMES.DC]: 'eeee MMMM d K:mma',
  [THEME_NAMES.FLORIDA]: 'eeee MMM d K:mma',
  default: 'eeee MMM d K:mma',
};

const EventListing = ({ event }: EventListingProps) => {
  const { theme_name } = useSite();
  const dateFormatPattern = getThemeValue(theme_name, themeDateFormats);
  const [dayOfWeek, month, day, time] = format(
    new Date(event.start),
    dateFormatPattern,
  ).split(' ');
  const eventHref = `/e/${event.id}`;

  return (
    <article
      itemScope
      itemType="http://data-vocabulary.org/Event"
      className="listing"
    >
      <div className="row">
        <div className="col-lg-9">
          <header className="listing-header">
            <time
              itemProp="startDate"
              dateTime={event.start}
              className="listing-date"
            >
              <span className="listing-date-item listing-date-item--day-name">
                {dayOfWeek}
              </span>
              <span className="listing-date-item listing-date-item--month-day">
                {month} {day}
              </span>
            </time>
            <h3>
              <a
                itemProp="url"
                href={eventHref}
                className="text-decoration-none"
              >
                <span itemProp="name">{event.name}</span>
              </a>
            </h3>
          </header>
          <div className="listing-details my-4">
            {event.html_artists && (
              <div
                className="server-content"
                dangerouslySetInnerHTML={{ __html: event.html_artists }}
              />
            )}
            <p className="mt-5">DOORS: {time}</p>
          </div>
        </div>
        <div className="col-lg-3">
          {event.url_image && (
            <div className="mb-4">
              <a href={eventHref}>
                <img
                  className="img-fluid img-greyscale img-thumbnail"
                  itemProp="photo"
                  src={event.url_image}
                  alt={`${event.name} event flyer`}
                  loading="lazy"
                />
              </a>
            </div>
          )}
          <EventActionButtons className="d-grid" event={event} />
          <TechnologyLinks
            urlData={event.urls.data}
            className="d-flex justify-content-center mt-4 gap-3"
          />
        </div>
      </div>
    </article>
  );
};

export default EventListing;
